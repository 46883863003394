body {
  background-image: url('../images/login-bg.jpg');
  background-size: cover;
}

#login {
  background-color: var(--white);
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 8% auto 0;
}

#loginform {
  background-color: transparent;
  border: none;
  padding: 0 0 30px;
  margin: 0 30px;
  border-bottom: 1px solid var(--black-30);
}

.login {
  label {
    color: var(--black);
    margin-bottom: 8px;
  }

  #backtoblog {
    a {
      text-decoration: none;
      color: #555d66;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  #nav {
    a {
      text-decoration: none;
      color: #555d66;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  h1 {
    border-bottom: 1px solid var(--black-30);
    padding-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;

    a {
      background-size: contain;
      background-position: center top;
      background-repeat: no-repeat;
      width: 175px;
      height: 50px;
      transform: translateX(-14px);
      margin-bottom: 0;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  #login_error {
    border-color: var(--color-primary);
  }

  .message {
    border-color: var(--color-primary);
    margin: 0 30px 30px;
    box-shadow: none;
  }
}

.login #nav,
.login #backblog {
  padding: 0 30px;
}

.login form .input,
.login input[type='text'],
.login input[type='password'] {
  margin-bottom: 20px;
}

.wp-core-ui {
  .button-primary {
    background: var(--color-primary);
    border-color: transparent;
    box-shadow: none;
    border-radius: 0;
    text-shadow: none;

    &:focus {
      background: var(--color-primary);
    }

    &:hover {
      background: var(--color-primary);
    }
  }

  .button-primary.focus {
    background: var(--color-primary);
  }

  .button-primary.hover {
    background: var(--color-primary);
  }
}

a {
  &:focus {
    box-shadow: none;
  }
}

input[type='text'],
input[type='password'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='week'],
select,
textarea,
input[type='checkbox'] {
  border-radius: 0;
}
